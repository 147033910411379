import * as React from 'react';
import { BaseIconProps } from './types';

interface AvatarIconProps extends BaseIconProps {
  size?: '15';
  type?: 'normal' | 'twoTone';
}

export const AvatarIcon = ({
  color = 'currentColor',
  size = '15',
  type = 'normal',
  ...props
}: AvatarIconProps) => {
  if (type === 'normal' && size === '15') {
    return (
      <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M1.12704 7.49988C1.12704 3.98027 3.98026 1.12705 7.49988 1.12705C11.0195 1.12705 13.8727 3.98027 13.8727 7.49988C13.8727 11.0195 11.0195 13.8727 7.49988 13.8727C3.98026 13.8727 1.12704 11.0195 1.12704 7.49988ZM7.49988 2.07705C4.50493 2.07705 2.07704 4.50494 2.07704 7.49988C2.07704 8.8854 2.59666 10.1496 3.45166 11.1081C4.35361 9.84764 5.83058 9.025 7.50023 9.025C9.16969 9.025 10.6465 9.84744 11.5485 11.1077C12.4032 10.1492 12.9227 8.88519 12.9227 7.49988C12.9227 4.50494 10.4948 2.07705 7.49988 2.07705ZM10.8486 11.7655C10.1261 10.6851 8.89583 9.975 7.50023 9.975C6.10447 9.975 4.87404 10.6853 4.15162 11.7659C5.07358 12.4905 6.23626 12.9227 7.49988 12.9227C8.7637 12.9227 9.92656 12.4904 10.8486 11.7655ZM5.14999 6.50488C5.14999 5.20701 6.20212 4.15488 7.49999 4.15488C8.79786 4.15488 9.84999 5.20701 9.84999 6.50488C9.84999 7.80275 8.79786 8.85488 7.49999 8.85488C6.20212 8.85488 5.14999 7.80275 5.14999 6.50488ZM7.49999 5.10488C6.72679 5.10488 6.09999 5.73168 6.09999 6.50488C6.09999 7.27808 6.72679 7.90488 7.49999 7.90488C8.27319 7.90488 8.89999 7.27808 8.89999 6.50488C8.89999 5.73168 8.27319 5.10488 7.49999 5.10488Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  if (type === 'twoTone' && size === '15') {
    return (
      <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M11.8145 11.5209C12.7967 10.4675 13.3977 9.05385 13.3977 7.49988C13.3977 4.2426 10.7571 1.60205 7.49988 1.60205C4.2426 1.60205 1.60205 4.2426 1.60205 7.49988C1.60205 9.05409 2.20324 10.4679 3.18575 11.5214C3.16712 11.5538 3.14885 11.5864 3.13094 11.6192L3.46506 11.8016C4.51998 12.7915 5.93913 13.3977 7.49988 13.3977C9.06013 13.3977 10.4789 12.7918 11.5337 11.8025L11.8695 11.6192C11.8515 11.5862 11.8332 11.5535 11.8145 11.5209Z"
          fill={color}
          opacity=".2"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M1.12708 7.49988C1.12708 3.98027 3.98029 1.12705 7.49991 1.12705C11.0195 1.12705 13.8727 3.98027 13.8727 7.49988C13.8727 11.0195 11.0195 13.8727 7.49991 13.8727C3.98029 13.8727 1.12708 11.0195 1.12708 7.49988ZM7.49991 2.07705C4.50496 2.07705 2.07708 4.50494 2.07708 7.49988C2.07708 8.8854 2.59669 10.1496 3.45169 11.1081C4.35364 9.84764 5.83061 9.025 7.50026 9.025C9.16972 9.025 10.6465 9.84744 11.5485 11.1077C12.4033 10.1492 12.9227 8.88519 12.9227 7.49988C12.9227 4.50494 10.4948 2.07705 7.49991 2.07705ZM10.8486 11.7655C10.1262 10.6851 8.89586 9.975 7.50026 9.975C6.1045 9.975 4.87407 10.6853 4.15165 11.7659C5.07362 12.4905 6.23629 12.9227 7.49991 12.9227C8.76373 12.9227 9.92659 12.4904 10.8486 11.7655ZM5.15002 6.50488C5.15002 5.20701 6.20215 4.15488 7.50002 4.15488C8.79789 4.15488 9.85002 5.20701 9.85002 6.50488C9.85002 7.80275 8.79789 8.85488 7.50002 8.85488C6.20215 8.85488 5.15002 7.80275 5.15002 6.50488ZM7.50002 5.10488C6.72683 5.10488 6.10002 5.73168 6.10002 6.50488C6.10002 7.27808 6.72683 7.90488 7.50002 7.90488C8.27322 7.90488 8.90002 7.27808 8.90002 6.50488C8.90002 5.73168 8.27322 5.10488 7.50002 5.10488Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  console.error(`AvatarIcon doesn't support the combination of ${size} and ${type}`);
  return null;
};

export default AvatarIcon;
